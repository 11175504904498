import {reactive, toRefs, defineComponent,onBeforeMount, onMounted, getCurrentInstance,nextTick} from 'vue';
import WjdListUtil ,{IWjdListDataObj} from './wjdListUtil';
import PiCard from "@/views/project/order/order/pi/PiCard.vue";
export default defineComponent ({
    name: 'WjdList',
    props: {
        engineParams: {
            type: Object
        }
    },
    setup(props,context){
        const {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        let dataObj:IWjdListDataObj=reactive<IWjdListDataObj>({
            pageListRef:null,
            utilInst:{} as any,
            refMap:new Map(),
            pageList: {
                isShowFixCol:false,
                queryParam: {
                    from:'WjdList',
                },
                modelMethod: utils.OrderProviderApi.buildUrl('/wjd/pageData')
            },
            otherParams:{
            },
        })
        onBeforeMount(()=>{
            dataObj.utilInst=new WjdListUtil(proxy,dataObj);
        })
        onMounted(()=>{
            nextTick(async()=>{
                await dataObj.utilInst.buildSelectData();
            })
        })
        //如果超过了最迟交单日，那么把行背景改变一下吧
        const outDaysClass = (params:any) => {
            if(params.row.F_LESS_DAYS<0)return 'danger-row';//逾期了，改变行背景色
            return ''
        }
        //查看pi弹出框
        const piDetail=async (piId:string)=>{
            await utils.UtilPub.openDialog({proxy:proxy,addOrEdit:'edit',id:piId,modelComp:PiCard,ownerComp:proxy,cardFrom:'WjdList',
                title:'pi详情',contentHeight:'',overflow:'hidden',showFooterBtn:false,fullscreen:true})
        }
        return{
            ...toRefs(dataObj),outDaysClass,piDetail
        }
    }
});